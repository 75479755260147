export default async function getAgentMenuData() {
  return [
    {
      title: "Dashboards",
      key: "agentdashboard",
      icon: "fe fe-home",
      url: "/agentdashboard"
    },
    {
      category: true,
      title: "My Commission"
    },
    {
      title: "Commissions",
      key: "commissions",
      icon: "fa fa-rupee",
      url: "/commissions"
    },

    {
      title: "Payout",
      key: "agentpayouts",
      icon: "fa fa-rupee",
      url: "/agentpayouts"
    }
  ];
}
