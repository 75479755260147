import axios from "axios";

const request = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  withCredentials: true,
  headers: {
    "content-type": "application/json"
  }
});

request.interceptors.request.use(
  config => {
    const token = localStorage.getItem("token");

    if (token) {
      config.headers.authorization = `Bearer ${token}`;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

request.interceptors.response.use(
  response => {
    return Promise.resolve(response);
  }

  // error => {
  //   if (error.response.status === 401) {
  //     localStorage.removeItem('token');
  //     localStorage.removeItem('role');
  //   }

  //   if (error.response.status === 403) {
  //     localStorage.removeItem('token');
  //     localStorage.removeItem('role');
  //   }

  //   return Promise.reject(error);
  // },
);

export default request;
