import request from 'libs/request'

export const logout = async () => {
  try {
    return await request.post('/logout')
  } catch (err) {
    throw new Error(err);
  }
}

export const login = async (email, password) => {
  try {
    return await request.post(
      '/login',
      JSON.stringify({
        email,
        password,
      }),
    )
  } catch (err) {
    return Promise.reject(err);
  }
}

export const currentAccount = async () => {
  try {
    return await request('/user')
  } catch (err) {
    throw new Error(err);
  }
}
