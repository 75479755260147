import React, { lazy, Suspense } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { ConnectedRouter } from "connected-react-router";
import { CSSTransition, SwitchTransition } from "react-transition-group";
import { connect } from "react-redux";

import Layout from "layouts";

const routes = [
  // Auth Pages
  {
    path: "/auth/login",
    Component: lazy(() => import("pages/auth/login")),
    exact: true
  },
  // Dashboards
  {
    path: "/dashboard",
    Component: lazy(() => import("pages/dashboard")),
    exact: true
  },
  // Catalog
  {
    path: "/catalog",
    Component: lazy(() => import("pages/catalog")),
    exact: true
  },
  {
    path: "/catalog/new",
    Component: lazy(() => import("pages/catalog/create")),
    exact: true
  },
  {
    path: "/catalog/edit/:id",
    Component: lazy(() => import("pages/catalog/edit")),
    exact: true
  },
  {
    path: "/product/new",
    Component: lazy(() => import("pages/product/create")),
    exact: true
  },
  {
    path: "/products",
    Component: lazy(() => import("pages/product")),
    exact: true
  },
  {
    path: "/product/edit/:id",
    Component: lazy(() => import("pages/product/edit")),
    exact: true
  },
  {
    path: "/banners",
    Component: lazy(() => import("pages/banners")),
    exact: true
  },
  {
    path: "/banner/new",
    Component: lazy(() => import("pages/banners/create")),
    exact: true
  },
  {
    path: "/banners/edit/:id",
    Component: lazy(() => import("pages/banners/edit")),
    exact: true
  },
  {
    path: "/dealofweeek",
    Component: lazy(() => import("pages/dealbanner")),
    exact: true
  },
  {
    path: "/dealofweeek/new",
    Component: lazy(() => import("pages/dealbanner/create")),
    exact: true
  },
  {
    path: "/dealofweeek/edit/:id",
    Component: lazy(() => import("pages/dealbanner/edit")),
    exact: true
  },
  {
    path: "/coupons",
    Component: lazy(() => import("pages/coupon")),
    exact: true
  },
  {
    path: "/coupon/new",
    Component: lazy(() => import("pages/coupon/create")),
    exact: true
  },
  {
    path: "/coupon/edit/:id",
    Component: lazy(() => import("pages/coupon/edit")),
    exact: true
  },
  {
    path: "/timeslots",
    Component: lazy(() => import("pages/timeslot")),
    exact: true
  },
  {
    path: "/customers",
    Component: lazy(() => import("pages/customer")),
    exact: true
  },
  {
    path: "/customer/:id",
    Component: lazy(() => import("pages/customer/view")),
    exact: true
  },
  {
    path: "/adminusers",
    Component: lazy(() => import("pages/adminuser")),
    exact: true
  },
  {
    path: "/adminuser/new",
    Component: lazy(() => import("pages/adminuser/create")),
    exact: true
  },
  {
    path: "/orders",
    Component: lazy(() => import("pages/order")),
    exact: true
  },
  {
    path: "/order/create",
    Component: lazy(() => import("components/app/Order/create")),
    exact: true
  },
  {
    path: "/order/:id",
    Component: lazy(() => import("pages/order/details")),
    exact: true
  },
  {
    path: "/orders/map/:section",
    Component: lazy(() => import("pages/order/map")),
    exact: true
  },
  {
    path: "/delivery-users",
    Component: lazy(() => import("pages/deliveryuser")),
    exact: true
  },
  {
    path: "/delivery-users/orders/:id",
    Component: lazy(() => import("pages/deliveryuser/orders")),
    exact: true
  },
  {
    path: "/settings",
    Component: lazy(() => import("components/app/Setting")),
    exact: true
  },
  {
    path: "/attributes",
    Component: lazy(() => import("components/app/Attribute")),
    exact: true
  },
  {
    path: "/subscriptions",
    Component: lazy(() => import("components/app/Subscription")),
    exact: true
  },
  {
    path: "/subscription/:id",
    Component: lazy(() => import("components/app/Subscription/view")),
    exact: true
  },
  {
    path: "/recharges",
    Component: lazy(() => import("components/app/Recharges")),
    exact: true
  },
  {
    path: "/salereport",
    Component: lazy(() => import("components/app/Report")),
    exact: true
  },
  {
    path: "/shippingreport",
    Component: lazy(() => import("components/app/Report/shippingReport")),
    exact: true
  },
  {
    path: "/report/productreview",
    Component: lazy(() => import("components/app/Report/productReviewReport")),
    exact: true
  },
  {
    path: "/report/bestselling",
    Component: lazy(() =>
      import("components/app/Report/bestSellingProductReport")
    ),
    exact: true
  },
  {
    path: "/report/productsincart",
    Component: lazy(() => import("components/app/Report/productsInCart")),
    exact: true
  },
  {
    path: "/report/subscriptions",
    Component: lazy(() => import("components/app/Report/subscriptionsReport")),
    exact: true
  },
  // Collections
  {
    path: "/collection",
    Component: lazy(() => import("pages/collection")),
    exact: true
  },
  {
    path: "/collection/new",
    Component: lazy(() => import("pages/collection/create")),
    exact: true
  },
  {
    path: "/collection/edit/:id",
    Component: lazy(() => import("pages/collection/edit")),
    exact: true
  },
  // agent
  {
    path: "/agent",
    Component: lazy(() => import("pages/agent")),
    exact: true
  },
  {
    path: "/agent/new",
    Component: lazy(() => import("pages/agent/create")),
    exact: true
  },
  {
    path: "/agent/edit/:id",
    Component: lazy(() => import("pages/agent/edit")),
    exact: true
  },
  {
    path: "/agent/view/:id",
    Component: lazy(() => import("pages/agent/view")),
    exact: true
  },
  {
    path: "/agent/viewpayout/:id",
    Component: lazy(() => import("pages/agent/viewpayout")),
    exact: true
  },
  {
    path: "/agents-commissions",
    Component: lazy(() => import("pages/agentcommission")),
    exact: true
  },
  // Dashboards
  {
    path: "/agentdashboard",
    Component: lazy(() => import("pages/agentdashboard")),
    exact: true
  },
  {
    path: "/commissions",
    Component: lazy(() => import("pages/commissions")),
    exact: true
  },
  {
    path: "/agentpayouts",
    Component: lazy(() => import("pages/agentpayouts")),
    exact: true
  },
  {
    path: "/contentmanagement",
    Component: lazy(() => import("pages/contentmanagement")),
    exact: true
  },
  {
    path: "/contentmanagement/new",
    Component: lazy(() => import("pages/contentmanagement/create")),
    exact: true
  },
  {
    path: "/pagecontent/:id/edit",
    Component: lazy(() => import("pages/contentmanagement/edit")),
    exact: true
  }
];

const mapStateToProps = ({ settings }) => ({
  routerAnimation: settings.routerAnimation
});

const Router = ({ history, routerAnimation }) => {
  return (
    <ConnectedRouter history={history}>
      <Layout>
        <Route
          render={state => {
            const { location } = state;
            return (
              <SwitchTransition>
                <CSSTransition
                  key={location.pathname}
                  appear
                  classNames={routerAnimation}
                  timeout={routerAnimation === "none" ? 0 : 300}
                >
                  <Switch location={location}>
                    <Route
                      exact
                      path="/"
                      render={() => <Redirect to="/dashboard" />}
                    />
                    {routes.map(({ path, Component, exact }) => (
                      <Route
                        path={path}
                        key={path}
                        exact={exact}
                        render={() => {
                          return (
                            <div className={routerAnimation}>
                              <Suspense fallback={null}>
                                <Component location={location} />
                              </Suspense>
                            </div>
                          );
                        }}
                      />
                    ))}
                    <Redirect to="/auth/404" />
                  </Switch>
                </CSSTransition>
              </SwitchTransition>
            );
          }}
        />
      </Layout>
    </ConnectedRouter>
  );
};

export default connect(mapStateToProps)(Router);
