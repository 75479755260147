import { all, takeEvery, put, call } from "redux-saga/effects";
import { currentAccount, logout } from "services/auth.service";
import { history } from "index";
import actions from "./actions";
import get from "lodash/get";

export function* LOAD_CURRENT_ACCOUNT() {
  yield put({
    type: "user/SET_STATE",
    payload: {
      loading: true
    }
  });
  try {
    const response = yield call(currentAccount);
    if (response) {
      const { id, email, first_name } = response.data.data;
      const userRole = get(response.data.data, "roles[0].name");
      yield put({
        type: "user/SET_STATE",
        payload: {
          id,
          name: first_name,
          email,
          role: userRole,
          authorized: true
        }
      });
      // yield history.push('/')
    }
  } catch (err) {
    history.push("/auth/login");
  }
  yield put({
    type: "user/SET_STATE",
    payload: {
      loading: false
    }
  });
}

export function* LOGOUT() {
  yield call(logout);
  yield put({
    type: "user/SET_STATE",
    payload: {
      id: "",
      name: "",
      role: "",
      email: "",
      avatar: "",
      authorized: false,
      loading: false
    }
  });
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_CURRENT_ACCOUNT, LOAD_CURRENT_ACCOUNT),
    takeEvery(actions.LOGOUT, LOGOUT),
    LOAD_CURRENT_ACCOUNT() // run once on app load to check user auth
  ]);
}
