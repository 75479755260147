import { all, put, call } from "redux-saga/effects";
import getMenuData from "services/menu.service";
import getAgentMenuData from "services/agentmenu.service";

export function* GET_DATA() {
  const userRole = localStorage.getItem("role");
  const menuData = yield call(
    userRole === "admin" ? getMenuData : getAgentMenuData
  );

  yield put({
    type: "menu/SET_STATE",
    payload: {
      menuData
    }
  });
}

export default function* rootSaga() {
  yield all([
    GET_DATA() // run once on app load to fetch menu data
  ]);
}
