export default async function getMenuData() {
  return [
    {
      title: "Dashboards",
      key: "dashboards",
      icon: "fe fe-home",
      url: "/dashboard"
    },
    {
      category: true,
      title: "Catalog & Products"
    },
    {
      title: "Catalog",
      key: "catalog",
      icon: "fe fe-tag",
      url: "/catalog"
    },
    // {
    //   title: "Attribute",
    //   key: "attribute",
    //   icon: "fa fa-paperclip",
    //   url: "/attributes",
    // },
    {
      title: "Collections",
      key: "collection",
      icon: "fe fe-layers",
      url: "/collection"
    },
    {
      title: "Products",
      key: "products",
      icon: "fe fe-package",
      url: "/products"
    },
    // {
    //   category: true,
    //   title: "Stores & Timeslots",
    // },
    // {
    //   title: "Timeslots",
    //   key: "timeslots",
    //   icon: "fe fe-clock",
    //   url: "/timeslots"
    // },

    {
      category: true,
      title: "Marketing"
    },
    {
      title: "Coupons",
      key: "coupons",
      icon: "fe fe-percent",
      url: "/coupons"
    },
    // {
    //   title: 'Offers',
    //   key: 'offer',
    //   icon: 'fe fe-gift',
    //   url: '/ui-kits/antd',
    // },
    {
      category: true,
      title: "Sales & Reports"
    },
    {
      title: "Orders",
      key: "orders",
      icon: "fe fe-list",
      url: "/orders"
    },
    // {
    //   title: "Subscriptions",
    //   key: "subscriptions",
    //   icon: "fe fe-repeat",
    //   url: "/subscriptions"
    // },
    // {
    //   title: "Recharge History",
    //   key: "recharges",
    //   icon: "fa fa-rupee",
    //   url: "/recharges"
    // },
    {
      title: "Agents Commissions",
      key: "agents_commissions",
      icon: "fa fa-rupee",
      url: "/agents-commissions"
    },
    {
      title: "Reports",
      key: "reports",
      icon: "fa fa-line-chart",
      children: [
        {
          title: "Sales report",
          key: "sales_report",
          url: "/salereport"
        },
        {
          title: "Shipping collected",
          key: "shipping_collected",
          url: "/shippingreport"
        },
        {
          title: "Best selling product",
          key: "ordered_product",
          url: "/report/bestselling"
        },
        // {
        //   title: "Subscription",
        //   key: "subscription_report",
        //   url: "/report/subscriptions"
        // },
        {
          title: "Product review",
          key: "product_review",
          url: "/report/productreview"
        }
        // {
        //   title: 'Products in cart',
        //   key: 'product_in_cart',
        //   url: '/report/productsincart',
        // },
      ]
    },

    {
      category: true,
      title: "Users"
    },
    {
      title: "Agent",
      key: "agent",
      icon: "fe fe-user",
      url: "/agent"
    },
    {
      title: "Customers",
      key: "customers",
      icon: "fa fa-users",
      url: "/customers"
    },
    {
      title: "Admin users",
      key: "adminusers",
      icon: "fe fe-user",
      url: "/adminusers"
    },
    {
      title: "Delivery boy",
      key: "deliveryboy",
      icon: "fa fa-bicycle",
      url: "/delivery-users"
    },
    {
      category: true,
      title: "Settings"
    },

    {
      title: "Settings",
      key: "settings",
      icon: "fe fe-settings",
      url: "/settings"
    },
    {
      category: true,
      title: "Design"
    },
    {
      title: "Banners",
      key: "banners",
      icon: "lnr lnr-picture",
      url: "/banners"
    },
    {
      title: "Deal of the Week",
      key: "dealofweeek",
      icon: "lnr lnr-picture",
      url: "/dealofweeek"
    },
    // {
    //   title: 'Email Templates',
    //   key: 'emailTemplates',
    //   icon: 'fe fe-mail',
    //   url: '/advanced/email-templates',
    // },
    {
      title: "Content Management",
      key: "contentmanagement",
      icon: "lnr lnr-pencil",
      url: "/contentmanagement"
    }
  ];
}
